import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { DraftHandleValue, Editor, EditorState, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import * as yup from 'yup';
import CharLimit from './char-limit';
import {
  BLOCK_TYPES,
  createState,
  CUSTOM_STYLE_MAP,
  getBlockStyle,
  getCharCount,
  getWordCount,
  INLINE_STYLES,
  STATE_TO_HTML_OPTIONS,
} from './helpers';
import WordLimit from './word-limit';

export type Counter = {
  charCount: number;
  wordCount: number;
};

interface Props {
  charLimit?: number;
  defaultValue?: string;
  minHeight?: number;
  onChange?: (html: string, counter: Counter) => void;
  onFocusChanged?: (isFocused: boolean) => void;
  placeholder?: string;
  wordLimit?: number;
}

export interface RichTextEditorRef {
  clear(): void;
  focus(): void;
}

export const RichTextEditor = forwardRef<RichTextEditorRef, Props>(
  (props, ref) => {
    const {
      charLimit,
      defaultValue,
      minHeight,
      onChange,
      onFocusChanged,
      placeholder,
      wordLimit,
    } = props;

    const editorRef = useRef<Editor | null>(null);

    const [editorState, setEditorState] = useState<EditorState>(createState());

    const [isFocused, setFocused] = useState(false);

    useEffect(() => {
      setEditorState(createState(defaultValue));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
      clear() {
        setEditorState(createState());
      },
      focus() {
        editorRef.current?.focus();
      },
    }));

    useEffect(() => {
      if (onChange) {
        onChange(
          stateToHTML(editorState.getCurrentContent(), STATE_TO_HTML_OPTIONS),
          {
            charCount: getCharCount(editorState),
            wordCount: getWordCount(editorState),
          }
        );
      }
    }, [editorState, onChange]);

    useEffect(() => {
      if (onFocusChanged) {
        onFocusChanged(isFocused);
      }
    }, [isFocused, onFocusChanged]);

    const handleKeyCommand = useCallback<
      (
        command: string,
        editorState: EditorState,
        eventTimeStamp: number
      ) => DraftHandleValue
    >((command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);

      if (newState) {
        setEditorState(newState);

        return 'handled';
      }

      return 'not-handled';
    }, []);

    const onBlur = useCallback(() => {
      setFocused(false);
    }, []);

    const onFocus = useCallback(() => {
      setFocused(true);
    }, []);

    const onToggleBlockType = useCallback<
      (blockType: string) => React.MouseEventHandler<HTMLButtonElement>
    >(
      (blockType) => {
        return (e) => {
          e.preventDefault();
          e.stopPropagation();
          setEditorState(RichUtils.toggleBlockType(editorState, blockType));
        };
      },
      [editorState]
    );

    const onToggleInlineStyle = useCallback<
      (inlineStyle: string) => React.MouseEventHandler<HTMLButtonElement>
    >(
      (inlineStyle) => {
        return (e) => {
          e.preventDefault();
          e.stopPropagation();
          setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
        };
      },
      [editorState]
    );

    const promptForLink = useCallback<
      React.MouseEventHandler<HTMLButtonElement>
    >(
      (e) => {
        e.preventDefault();
        e.stopPropagation();

        const selection = editorState.getSelection();

        if (!selection.isCollapsed()) {
          const contentState = editorState.getCurrentContent();
          const startKey = editorState.getSelection().getStartKey();
          const startOffset = editorState.getSelection().getStartOffset();
          const blockWithLinkAtBeginning =
            contentState.getBlockForKey(startKey);
          const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

          let url = '';
          if (linkKey) {
            const linkInstance = contentState.getEntity(linkKey);
            url = linkInstance.getData().url;
          }

          const newUrl = prompt('Enter URL', url);

          if (newUrl) {
            yup
              .string()
              .url('URL is invalid')
              .validate(newUrl)
              .then(() => {
                const contentStateWithEntity = contentState.createEntity(
                  'LINK',
                  'MUTABLE',
                  { url: newUrl }
                );
                const entityKey =
                  contentStateWithEntity.getLastCreatedEntityKey();
                const newEditorState = EditorState.set(editorState, {
                  currentContent: contentStateWithEntity,
                });

                setEditorState(
                  RichUtils.toggleLink(
                    newEditorState,
                    newEditorState.getSelection(),
                    entityKey
                  )
                );
              })
              .catch((e) => {
                if (e instanceof yup.ValidationError) {
                  alert(e.message);
                }
              });
          }
        }
      },
      [editorState]
    );

    const removeLink = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
      (e) => {
        e.preventDefault();
        e.stopPropagation();

        const selection = editorState.getSelection();

        if (!selection.isCollapsed()) {
          setEditorState(RichUtils.toggleLink(editorState, selection, null));
        }
      },
      [editorState]
    );

    const currentBlockType = useMemo(() => {
      const selection = editorState.getSelection();

      return editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
    }, [editorState]);

    const currentInlineStyle = editorState.getCurrentInlineStyle();

    return (
      <div className="space-y-2">
        <div
          className={clsx(
            'space-y-2 rounded border border-secondary-grey-dark bg-white px-2 pt-2 transition-shadow',
            isFocused ? 'shadow-lg' : 'shadow'
          )}
          onClick={() => editorRef.current?.focus()}
        >
          <div
            style={{
              minHeight: minHeight ?? 64,
            }}
          >
            <Editor
              ref={editorRef}
              spellCheck
              blockStyleFn={getBlockStyle}
              customStyleMap={CUSTOM_STYLE_MAP}
              editorKey="universal-editor"
              editorState={editorState}
              handleKeyCommand={handleKeyCommand}
              placeholder={placeholder}
              onBlur={onBlur}
              onChange={setEditorState}
              onFocus={onFocus}
            />
          </div>
          <div className="flex h-[32px] items-center gap-4">
            {INLINE_STYLES.map((inlineStyle) => (
              <button
                key={inlineStyle.style}
                className={
                  currentInlineStyle.has(inlineStyle.style)
                    ? 'text-text-main'
                    : 'text-text-grey'
                }
                type="button"
                onClick={onToggleInlineStyle(inlineStyle.style)}
              >
                {inlineStyle.label}
              </button>
            ))}
            {BLOCK_TYPES.map((blockType) => (
              <button
                key={blockType.style}
                className={
                  currentBlockType === blockType.style
                    ? 'text-text-main'
                    : 'text-text-grey'
                }
                type="button"
                onClick={onToggleBlockType(blockType.style)}
              >
                {blockType.label}
              </button>
            ))}
            {RichUtils.currentBlockContainsLink(editorState) ? (
              <button
                className="text-text-main disabled:cursor-not-allowed disabled:text-text-grey"
                disabled={editorState.getSelection().isCollapsed()}
                type="button"
                onClick={removeLink}
              >
                <svg
                  fill="currentColor"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M17 7h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1 0 1.43-.98 2.63-2.31 2.98l1.46 1.46C20.88 15.61 22 13.95 22 12c0-2.76-2.24-5-5-5zm-1 4h-2.19l2 2H16zM2 4.27l3.11 3.11C3.29 8.12 2 9.91 2 12c0 2.76 2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1 0-1.59 1.21-2.9 2.76-3.07L8.73 11H8v2h2.73L13 15.27V17h1.73l4.01 4L20 19.74 3.27 3 2 4.27z" />
                  <path d="M0 24V0" fill="none" />
                </svg>
              </button>
            ) : (
              <button
                className="text-text-main disabled:cursor-not-allowed disabled:text-text-grey"
                disabled={editorState.getSelection().isCollapsed()}
                type="button"
                onClick={promptForLink}
              >
                <svg
                  fill="currentColor"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" />
                </svg>
              </button>
            )}
          </div>
        </div>
        {!!charLimit &&
          (isFocused || editorState.getCurrentContent().hasText()) && (
            <CharLimit charLimit={charLimit} editorState={editorState} />
          )}
        {!!wordLimit &&
          (isFocused || editorState.getCurrentContent().hasText()) && (
            <WordLimit editorState={editorState} wordLimit={wordLimit} />
          )}
        <style global jsx>
          {`
            .public-DraftStyleDefault-ol {
              margin: 0;
            }

            .public-DraftStyleDefault-ul {
              margin: 0;
            }
          `}
        </style>
      </div>
    );
  }
);
