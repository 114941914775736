'use client'; // https://www.blocknotejs.org/docs/advanced/nextjs - can't use blocknote in server side
import React, { useState, useEffect } from 'react';
import { type Block } from '@blocknote/core';
import { useCreateBlockNote } from '@blocknote/react';
import { VideoCameraIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { BlockHeading } from '../../typography';
import { BLOCK_WIDTH_CLASS } from '../constants';

export interface VideoBlockProps {
  content: {
    // eslint-disable-next-line
    description?: Block[];
    heading?: string;
    posterImageUrl?: string;
    variant:
      | 'text_with_left_video'
      | 'text_with_right_video'
      | 'full_size_video';
    videoUrl: string;
  };
  descriptionHTML?: string;
  formattedUrl?: string | false;
  isSocialVideo?: boolean;
}

export function isYouTubeOrVimeoLink(videoUrl?: string | null) {
  if (!videoUrl) return false;

  return (
    videoUrl.includes('youtube.com') ||
    videoUrl.includes('youtu.be') ||
    videoUrl.includes('vimeo.com')
  );
}

export function getVideoUrl(value?: string | null) {
  if (!value) return false;

  try {
    const result = new URL(value);

    return result;
  } catch (error) {
    return false;
  }
}

export function formatVideoUrl(videoUrl: string) {
  const url = getVideoUrl(videoUrl);

  if (!url) return false;

  if (url.hostname.endsWith('youtube.com')) {
    if (url.pathname.startsWith('/embed')) {
      const id = url.pathname.split('/')[2];

      return `https://www.youtube.com/embed/${id}`;
    }

    const id = url.searchParams.get('v');

    if (id) {
      return `https://www.youtube.com/embed/${id}`;
    }
  }

  if (url.hostname.endsWith('youtu.be')) {
    const id = url.pathname.split('/')[1];

    return `https://www.youtube.com/embed/${id}`;
  }

  if (url.hostname === 'player.vimeo.com') {
    const id = url.pathname.split('/')[2];

    return `https://player.vimeo.com/video/${id}`;
  }

  if (url.hostname.endsWith('vimeo.com')) {
    const id = url.pathname.split('/')[1];

    return `https://player.vimeo.com/video/${id}`;
  }

  return false;
}

function VideoItem({ content, formattedUrl, isSocialVideo }: VideoBlockProps) {
  return content.videoUrl ? (
    isSocialVideo && formattedUrl ? (
      // eslint-disable-next-line jsx-a11y/iframe-has-title
      <iframe
        allowFullScreen
        className="aspect-video h-full w-full overflow-hidden rounded-lg"
        src={formattedUrl}
      />
    ) : (
      <video
        controls
        className="w-full overflow-hidden rounded-lg"
        poster={content.posterImageUrl}
        src={content.videoUrl}
      />
    )
  ) : (
    <div className="flex aspect-video justify-center p-4">
      <div className="flex w-full items-center justify-center gap-2 rounded-lg bg-gray-100 p-4 text-gray-500">
        <VideoCameraIcon className="h-6 w-6" />
        <span className="text-sm">No video</span>
      </div>
    </div>
  );
}

export const VideoBlock: React.ComponentType<VideoBlockProps> = ({
  content,
}) => {
  const isSocialVideo = isYouTubeOrVimeoLink(content.videoUrl);
  const formattedUrl = formatVideoUrl(content.videoUrl || '');

  const [descriptionHTML, setDescriptionHTML] = useState<string>('');
  const editor = useCreateBlockNote();

  useEffect(() => {
    const convertDescriptionToHTML = async () => {
      if (content.description) {
        const html = await editor.blocksToHTMLLossy(content.description);
        setDescriptionHTML(html);
      }
    };

    convertDescriptionToHTML();
  }, [editor, content.description]);
  if (
    content.variant === 'text_with_left_video' ||
    content.variant === 'text_with_right_video'
  ) {
    return (
      <VideoBlockWithText
        content={content}
        descriptionHTML={descriptionHTML}
        formattedUrl={formattedUrl}
        isSocialVideo={isSocialVideo}
      />
    );
  }
  return (
    <FullSizeVideoBlock
      content={content}
      descriptionHTML={descriptionHTML}
      formattedUrl={formattedUrl}
      isSocialVideo={isSocialVideo}
    />
  );
};

const VideoBlockWithText: React.ComponentType<VideoBlockProps> = ({
  content,
  descriptionHTML,
  formattedUrl,
  isSocialVideo,
}) => {
  const isLeft = content.variant === 'text_with_left_video';

  return (
    <div
      className={clsx(
        BLOCK_WIDTH_CLASS,
        'flex flex-col items-start justify-between gap-8 md:grid md:grid-cols-2 md:gap-20'
      )}
    >
      <div className={clsx('flex w-full', isLeft ? 'order-1' : 'md:order-3')}>
        <VideoItem
          content={content}
          formattedUrl={formattedUrl}
          isSocialVideo={isSocialVideo}
        />
      </div>
      {/* This ugly logic wrapper allows us to conditionally render in a few ways:
          + if no heading or description, dont show parent block (there's a big gap underneath full size video if dont have this)
          + if heading or description, render parent block and relevant content block. 
      */}
      {(content.heading !== '' ||
        descriptionHTML !== '<p class="bn-inline-content"></p>') && (
        <div className="order-2 my-auto flex flex-col gap-4">
          {content.heading && content.heading !== '' && (
            <BlockHeading>{content.heading}</BlockHeading>
          )}
          {descriptionHTML && (
            <div
              dangerouslySetInnerHTML={{ __html: descriptionHTML }}
              className="prose max-w-none font-body text-lg leading-normal text-gray-700 "
            />
          )}
        </div>
      )}
    </div>
  );
};

const FullSizeVideoBlock: React.ComponentType<VideoBlockProps> = ({
  content,
  descriptionHTML,
  formattedUrl,
  isSocialVideo,
}) => {
  return (
    <div className={clsx(BLOCK_WIDTH_CLASS, 'relative flex flex-col gap-10')}>
      {content.videoUrl ? (
        <div className="flex w-full">
          <VideoItem
            content={content}
            formattedUrl={formattedUrl}
            isSocialVideo={isSocialVideo}
          />
        </div>
      ) : (
        <div className="flex aspect-video justify-center p-4">
          <div className="flex w-full items-center justify-center gap-2 rounded-lg bg-gray-100 p-4 text-gray-500">
            <VideoCameraIcon className="h-6 w-6" />
            <span className="text-sm">No video</span>
          </div>
        </div>
      )}
      {/* This ugly logic wrapper allows us to conditionally render in a few ways:
          + if no heading or description, dont show parent block (there's a big gap underneath full size video if dont have this)
          + if heading or description, render parent block and relevant content block. 
      */}
      {(content.heading !== '' ||
        descriptionHTML !== '<p class="bn-inline-content"></p>') && (
        <div className="flex max-w-4xl flex-col gap-8">
          {content.heading && content.heading !== '' && (
            <BlockHeading className="!pb-0">{content.heading}</BlockHeading>
          )}
          {descriptionHTML &&
            descriptionHTML !== '<p class="bn-inline-content"></p>' && (
              <div
                dangerouslySetInnerHTML={{ __html: descriptionHTML }}
                className="prose max-w-none font-body text-lg leading-normal text-gray-700 "
              />
            )}
        </div>
      )}
    </div>
  );
};
