import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import Typography from './typography';

type OptionValue = string | number;

type Option<SelectType extends OptionValue> = {
  disabled?: boolean;
  label: string;
  value: SelectType;
};

export type SelectProps<SelectType extends OptionValue> = {
  className?: string;
  disabled?: boolean;
  label: string;
  onChange: (value: SelectType) => void;
  options: Option<SelectType>[];
  placeholder?: string;
  value: SelectType;
};

export const Select = <SelectType extends OptionValue>({
  className,
  disabled,
  label,
  onChange,
  options,
  placeholder,
  value,
}: SelectProps<SelectType>) => (
  <Listbox disabled={disabled} value={value} onChange={onChange}>
    {({ open }) => (
      <>
        {label && (
          <Listbox.Label className="block font-system">
            <Typography component="span" variant="button">
              {label}
            </Typography>
          </Listbox.Label>
        )}
        <div className="relative mt-1">
          <Listbox.Button
            className={clsx(
              `group relative w-full cursor-pointer rounded-md border border-secondary-grey-light bg-white py-2 pl-3 pr-10 text-left text-base font-normal text-text-main shadow transition-all hover:border-gray-300 focus:border-gray-400 focus:outline-none md:hover:shadow-md`,
              className
            )}
          >
            {options.find((option) => option.value === value)?.label ? (
              <span className="block truncate font-system">
                {options.find((option) => option.value === value)?.label}
              </span>
            ) : (
              <span className="block truncate font-system text-text-disabled">
                {placeholder ?? ''}
              </span>
            )}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon
                aria-hidden="true"
                className="size-5 stroke-1 text-gray-400 transition-colors group-hover:text-gray-600"
              />
            </span>
          </Listbox.Button>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            show={open}
          >
            <Listbox.Options className="absolute z-20 mt-1 max-h-60 w-full cursor-pointer overflow-auto rounded-md bg-white py-1 text-base font-normal shadow-lg ring-1 ring-black/5 focus:outline-none">
              {options.map((option) => (
                <Listbox.Option
                  key={option.value}
                  className={({ active, disabled }) =>
                    // issue with components across athena and hermes and custom colours
                    // eslint-disable-next-line tailwindcss/no-contradicting-classname
                    clsx(
                      active
                        ? 'bg-primary-grey-dark text-white'
                        : 'text-text-main',
                      disabled && 'cursor-not-allowed opacity-50',
                      'relative cursor-default select-none py-2 pl-3 pr-9 font-system'
                    )
                  }
                  disabled={option.disabled}
                  value={option.value}
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={clsx(
                          selected ? 'font-semibold' : 'font-normal',
                          'block truncate font-system'
                        )}
                      >
                        {option.label}
                      </span>

                      {selected ? (
                        <span
                          // issue with components across athena and hermes and custom colours
                          // eslint-disable-next-line tailwindcss/no-contradicting-classname
                          className={clsx(
                            active
                              ? 'text-white'
                              : 'text-company-primary-text text-primary-green-dark',
                            'absolute inset-y-0 right-0 flex items-center pr-4 font-system'
                          )}
                        >
                          <CheckIcon aria-hidden="true" className="h-5 w-5" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </>
    )}
  </Listbox>
);
