import { forwardRef } from 'react';
import clsx from 'clsx';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'tertiary-active';

export interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  color?: 'black' | 'grey' | 'white';
  disabled?: boolean;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: 'md' | 'sm';
  startIcon?: React.ReactNode;
  style?: React.CSSProperties;
  testId?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonVariant;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      color = 'black',
      disabled,
      endIcon,
      fullWidth,
      onClick,
      size = 'md',
      startIcon,
      style,
      testId,
      type = 'button',
      variant = 'primary',
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={clsx(
          'font-body',
          size === 'md' && 'min-h-[46px] px-4 py-2 text-base font-semibold',
          size === 'sm' && 'min-h-[32px] px-3 py-2 text-sm',
          'box-border flex items-center justify-center space-x-2 truncate rounded-lg !leading-tight tracking-[0.2px] outline-none disabled:cursor-not-allowed disabled:text-text-disabled',
          color === 'black' &&
            variant === 'primary' &&
            'bg-company-primary text-company-primary-text disabled:bg-secondary-grey-light',
          color === 'white' &&
            variant === 'primary' &&
            'bg-white text-text-main hover:text-gray-700 disabled:bg-secondary-grey-light',
          color === 'black' &&
            variant === 'secondary' &&
            'border border-company-primary text-company-primary disabled:border-secondary-grey-light',
          color === 'white' &&
            variant === 'secondary' &&
            'border border-white text-white disabled:border-secondary-grey-light',
          color === 'grey' &&
            variant === 'tertiary' &&
            'bg-gray-100 text-text-main active:bg-secondary-grey-light-2',
          variant === 'tertiary-active' && 'text-text-main underline',
          fullWidth && 'w-full',
          'transition-opacity duration-150 ease-in-out [&:not(:disabled)]:hover:opacity-90',
          className
        )}
        data-test={testId}
        disabled={disabled}
        style={style}
        type={type}
        onClick={onClick}
      >
        {startIcon}
        <span>{children}</span>
        {endIcon}
      </button>
    );
  }
);

export default Button;
