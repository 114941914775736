import { Switch } from '@headlessui/react';
import clsx from 'clsx';
export interface ToggleProps {
  checked: boolean;
  checkedClassname?: string;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

export const Toggle: React.ComponentType<ToggleProps> = ({
  checked,
  checkedClassname,
  disabled,
  onChange,
}) => {
  return (
    <Switch
      checked={checked}
      className={clsx(
        'relative inline-flex h-6  w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-0',
        checked
          ? checkedClassname
            ? checkedClassname
            : 'bg-primary-green-dark'
          : 'bg-secondary-grey-light',
        disabled &&
          'cursor-not-allowed bg-text-disabled focus:ring-0 focus:ring-offset-0'
      )}
      onChange={disabled || !onChange ? () => undefined : onChange}
    >
      <span
        aria-hidden="true"
        className={clsx(
          checked ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  );
};

export default Toggle;
