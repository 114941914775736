import { createElement } from 'react';
import clsx from 'clsx';

export interface TypographyProps {
  align?: 'left' | 'center' | 'right';
  children?: React.ReactNode;
  className?: string;
  component?: React.ElementType;
  noWrap?: boolean;
  style?: React.CSSProperties;
  testId?: string;
  title?: string;
  variant?:
    | 'display-large'
    | 'display-medium'
    | 'display-small'
    | 'large'
    | 'medium'
    | 'small'
    | 'heading-1'
    | 'heading-2'
    | 'heading-3'
    | 'heading-4'
    | 'heading-5'
    | 'subtitle-1'
    | 'subtitle-2'
    | 'body-large'
    | 'body-regular'
    | 'body-small'
    | 'body-xs'
    | 'paragraph'
    | 'fine-print-bold'
    | 'fine-print'
    | 'button'
    | 'system-xl'
    | 'system-large'
    | 'system-regular'
    | 'system-small'
    | 'system-xs'
    | 'badge';
}

/**
 * Run storybook to see all available variants.
 */
export const Typography: React.ComponentType<TypographyProps> = ({
  align = 'left',
  children,
  className,
  component = 'p',
  noWrap,
  style,
  testId,
  title,
  variant = 'body-regular',
}) => {
  return createElement(
    component,
    {
      className: clsx(
        variant === 'display-large' && 'typography-display-large',
        variant === 'display-medium' && 'typography-display-medium',
        variant === 'display-small' && 'typography-display-small',
        variant === 'large' && 'typography-large',
        variant === 'medium' && 'typography-medium',
        variant === 'small' && 'typography-small',
        variant === 'heading-1' && 'typography-heading-1',
        variant === 'heading-2' && 'typography-heading-2',
        variant === 'heading-3' && 'typography-heading-3',
        variant === 'heading-4' && 'typography-heading-4',
        variant === 'heading-5' && 'typography-heading-5',
        variant === 'subtitle-1' && 'typography-subtitle-1',
        variant === 'subtitle-2' && 'typography-subtitle-2',
        variant === 'body-large' && 'typography-body-large',
        variant === 'body-regular' && 'typography-body-regular',
        variant === 'body-small' && 'typography-body-small',
        variant === 'paragraph' && 'typography-paragraph',
        variant === 'fine-print-bold' && 'typography-fine-print-bold',
        variant === 'fine-print' && 'typography-fine-print',
        variant === 'button' && 'typography-button',
        variant === 'badge' && 'typography-badge',
        variant === 'system-xl' && 'typography-system-xl',
        variant === 'system-large' && 'typography-system-large',
        variant === 'system-regular' && 'typography-system-regular',
        variant === 'system-small' && 'typography-system-small',
        variant === 'system-xs' && 'typography-system-xs',
        align === 'left' && 'text-left',
        align === 'center' && 'text-center',
        align === 'right' && 'text-right',
        noWrap && 'truncate',
        className
      ),
      'data-test': testId,
      style,
      title,
    },
    children
  );
};

/* Hermes v2 */
interface TypeProps {
  children: React.ReactNode;
  className?: string;
  withUnderline?: boolean;
}

export const BlockHeading: React.ComponentType<TypeProps> = ({
  children,
  className,
  withUnderline,
}) => {
  return (
    <h2
      className={clsx(
        'pb-4 font-heading text-2xl !leading-tight tracking-normal text-gray-900 lg:text-[30px]',
        withUnderline && 'mb-8 border-b border-gray-200',
        className
      )}
    >
      {children}
    </h2>
  );
};

export default Typography;
