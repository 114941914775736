import { forwardRef } from 'react';
import clsx from 'clsx';

export interface LinkButtonProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
}

export const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>(
  ({ children, className, disabled, onClick, style }, ref) => {
    return (
      <button
        ref={ref}
        className={clsx(
          'hover:underline disabled:cursor-not-allowed disabled:text-text-disabled ',
          className
        )}
        disabled={disabled}
        style={style}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
);
