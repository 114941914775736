import React, { ReactNode } from 'react';
import QuestionMarkCircleIcon from '@heroicons/react/outline/QuestionMarkCircleIcon';
import clsx from 'clsx';
import { ITooltip, Tooltip as ReactTooltip } from 'react-tooltip';
import Typography from './typography';

interface Props {
  buttonClassName?: string;
  children?: ReactNode;
  containerClassname?: string;
  content?: string | ReactNode;
  description?: string;
  hover?: boolean;
  place?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end';
  testId?: string;
  title?: string;
}

export type TooltipProps = Omit<ITooltip, 'content'> & Props;

export const Tooltip: React.ComponentType<TooltipProps> = ({
  buttonClassName,
  children,
  containerClassname,
  content,
  description,
  place,
  testId,
  title,
}) => {
  const interimId = content
    ? content.toString()
    : description
    ? description
    : title
    ? title
    : '';

  const finalId = interimId.replace(/\W/g, '-');

  const target = (
    <div className={clsx('inline-block cursor-pointer', buttonClassName)}>
      <QuestionMarkCircleIcon className="h-4 w-4 text-gray-700" />
    </div>
  );

  return typeof content === 'string' ? (
    <div className="react-tooltip" data-test={testId}>
      <div id={finalId}>{children || target}</div>
      <ReactTooltip
        anchorSelect={`#${finalId}`}
        className={clsx('content', containerClassname)}
        place={place ? place : 'top'}
      >
        <Typography className="text-white" testId={testId} variant="body-small">
          {content}
        </Typography>
      </ReactTooltip>
    </div>
  ) : (
    <div className="react-tooltip" data-test={testId}>
      <div id={finalId}>{children || target}</div>
      <ReactTooltip
        anchorSelect={`#${finalId}`}
        className={clsx('content', containerClassname)}
        place="bottom-end"
      >
        {content}
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;
