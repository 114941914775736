import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from './button';
import Typography from './typography';

export interface ConfirmationModalProps {
  actionsDisabled?: boolean;
  description: string;
  icon?: React.ReactNode;
  isOpen: boolean;
  negativeButtonTitle: string;
  onNegativeActionClicked: () => void;
  onPositiveActionClicked: () => void;
  positiveButtonTitle: string;
  title: string;
}

export const ConfirmationModal: React.ComponentType<ConfirmationModalProps> = ({
  actionsDisabled = false,
  description,
  icon,
  isOpen,
  negativeButtonTitle,
  onNegativeActionClicked,
  onPositiveActionClicked,
  positiveButtonTitle,
  title,
}) => {
  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="relative z-40"
        onClose={onNegativeActionClicked}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-primary-off-black/40 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full overflow-hidden rounded-lg bg-white p-6 shadow-lg transition-all sm:max-w-[480px] sm:p-8 lg:max-w-[540px]">
                {icon}
                <Typography
                  align="center"
                  className={icon ? 'mt-3' : undefined}
                  variant="heading-4"
                >
                  {title}
                </Typography>
                <Typography align="center" className="mt-4">
                  {description}
                </Typography>
                <div className="mt-8 flex flex-col gap-4 sm:flex-row-reverse sm:justify-center">
                  <Button
                    disabled={actionsDisabled}
                    onClick={onPositiveActionClicked}
                  >
                    {positiveButtonTitle}
                  </Button>
                  <Button
                    disabled={actionsDisabled}
                    variant="secondary"
                    onClick={onNegativeActionClicked}
                  >
                    {negativeButtonTitle}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationModal;
