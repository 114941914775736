import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  Typography,
  Skeleton,
  MediaAnnouncement,
  useMediaAnnouncementsQuery,
  OptionsInput,
  BlockHeading,
  DiscoverBlockCard,
} from '../../index';
import { BLOCK_WIDTH_CLASS } from '../constants';

interface Props {
  client?: ApolloClient<NormalizedCacheObject>;

  content: {
    heading?: string;
  };
  hub: string;
  listingKey: string;
  marketKey: string;
  translate: (key: string) => string;
}

export const LatestAnnouncementsBlock: React.ComponentType<Props> = ({
  client,
  content,
  hub,
  listingKey,
  marketKey,
  translate,
}) => {
  let options: OptionsInput = {
    filters: [
      { key: 'market_key', value: marketKey },
      { key: 'ticker', value: listingKey },
    ],
    orders: [{ key: 'posted_at', value: 'desc' }],
  };

  // CCRTODO: Kalgoorlie Gold Mining wants to show market sensitive announcements
  if (marketKey.toLowerCase() === 'kal') {
    options = {
      ...options,
      filters: [
        {
          key: 'is_market_sensitive',
          value: 'true',
        },
      ],
    };
  }

  const { data, loading } = useMediaAnnouncementsQuery({
    client,
    fetchPolicy: 'network-only',
    variables: {
      first: 2,
      hub,
      options,
    },
  });

  const announcements = data?.mediaAnnouncements?.edges?.map(
    (edge) => edge?.node
  );

  const renderAnnouncements = () => (
    <div className="grid grid-cols-1 gap-4 md:grid md:grid-cols-2 md:gap-6">
      {announcements?.map((announcement) => {
        if (!announcement) return null;
        return (
          <DiscoverBlockCard
            key={announcement.id}
            announcement={announcement as MediaAnnouncement}
          />
        );
      })}
    </div>
  );

  const renderEmptyState = () =>
    !loading ? (
      <Typography className="text-text-secondary">
        <em>{`No ${translate(
          'announcements.lowercase'
        )} found for ${marketKey}:${listingKey}.`}</em>
      </Typography>
    ) : (
      <Skeleton height={170} loading={loading} variant="rect" />
    );

  return (
    <div className="scroll-mt-40 bg-white" id="latest_announcements_block">
      <div className={`${BLOCK_WIDTH_CLASS} mx-auto max-w-7xl`}>
        {content.heading && (
          <BlockHeading withUnderline className="mb-8 pb-8">
            {content.heading}
          </BlockHeading>
        )}

        {announcements && announcements.length > 0
          ? renderAnnouncements()
          : renderEmptyState()}
      </div>
    </div>
  );
};
