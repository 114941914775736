'use client'; // https://www.blocknotejs.org/docs/advanced/nextjs - can't use blocknote in server side
import React from 'react';
import { Block } from '@blocknote/core';
import { DownloadIcon, DocumentIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import draftToHtml from 'draftjs-to-html';
import Link from 'next/link';
import Typography, { BlockHeading } from '../../typography';
import { BLOCK_WIDTH_CLASS } from '../constants';
import RenderBlockNote from '../ui/render-block-note';

export type DocumentsBlockVariant = 'one_column' | 'two_columns';

export interface DocumentsBlockProps {
  content: {
    description?: Block[];
    documents?: {
      bytes: number;
      format: string;
      name: string;
      url: string;
    }[];
    heading?: string;
    migratedDescription?: string;
    openInNewTab?: boolean;
    variant?: DocumentsBlockVariant;
  };
}

export const DocumentsBlock: React.ComponentType<DocumentsBlockProps> = ({
  content,
}) => {
  return (
    <div className={clsx(BLOCK_WIDTH_CLASS, 'flex flex-col gap-3')}>
      {content.heading && content.heading.trim() !== '' && (
        <BlockHeading>{content.heading}</BlockHeading>
      )}
      {/* AS: In hub builder preview while editing content there's an extra gap for an empty description because of the interaction of the rich text element, but in the actual live website there is not */}
      {content.description && <RenderBlockNote content={content.description} />}
      {content.migratedDescription && (
        <div
          dangerouslySetInnerHTML={{
            __html: draftToHtml(JSON.parse(content.migratedDescription)),
          }}
          className="prose font-body text-lg leading-normal text-gray-700"
        />
      )}

      <div
        className={clsx(
          'grid gap-y-4',
          content.variant === 'one_column'
            ? 'grid-cols-1 justify-items-center'
            : 'grid-cols-1 justify-items-start gap-x-6 md:grid-cols-2'
        )}
      >
        {content.documents && content.documents.length
          ? content.documents.map((document, index) => (
              <Document
                key={`page-builder-document-${index}`}
                bytes={document.bytes}
                format={document.format}
                name={document.name}
                openInNewTab={content.openInNewTab}
                url={document.url}
                variant={content.variant}
              />
            ))
          : null}
      </div>
    </div>
  );
};

interface DocumentProps {
  bytes: number;
  format: string;
  name: string;
  openInNewTab?: boolean;
  style?: React.CSSProperties;
  url: string;
  variant?: DocumentsBlockVariant;
}

const Document: React.ComponentType<DocumentProps> = ({
  bytes,
  format,
  name,
  openInNewTab = false,
  style,
  url,
}) => {
  function getSize(bytes: number) {
    if (bytes === undefined) return '';

    if (bytes >= 1000000) {
      return `${(bytes / 1000000).toFixed(0)} MB`;
    } else if (bytes >= 1000) {
      return `${(bytes / 1000).toFixed(0)} KB`;
    } else {
      return `${bytes} B`;
    }
  }

  function getDownloadUrl(originalUrl: string) {
    if (originalUrl.includes('res.cloudinary.com')) {
      const parts = originalUrl.split('/upload/');
      return parts[0] + '/upload/fl_attachment/' + parts[1];
    } else {
      return originalUrl;
    }
  }

  const downloadUrl = url ? getDownloadUrl(url) : '#';

  return (
    <div className="box-border flex w-full items-center justify-between border-b border-gray-200 pb-2 pt-4">
      <Link
        className={`group flex flex-row items-start justify-start gap-2 ${
          url ? 'cursor-pointer' : 'cursor-not-allowed'
        }`}
        download={openInNewTab ? undefined : name}
        href={openInNewTab ? url : downloadUrl}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
        target={openInNewTab ? '_blank' : '_self'}
      >
        <DocumentIcon className="size-6 rounded-lg bg-none stroke-[1.5] text-gray-400 transition-colors group-hover:text-gray-600" />
        <div className="flex flex-col items-start gap-1">
          <Typography
            className={`font-body text-base font-normal  ${
              url
                ? 'text-gray-700 underline transition-colors group-hover:text-primary-off-black'
                : 'text-gray-500'
            } underline-offset-2`}
            style={style}
            variant="subtitle-1"
          >
            {name || 'Enter a name'}
          </Typography>
          {!!bytes && (
            <Typography
              className="font-body text-xs font-normal uppercase text-gray-500"
              style={style}
              variant="subtitle-2"
            >
              {getSize(bytes)}, {format}
            </Typography>
          )}
        </div>
      </Link>
      <Link
        className={`flex ${
          url ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
        }`}
        download={openInNewTab ? undefined : name}
        href={openInNewTab ? url : downloadUrl}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
        target={openInNewTab ? '_blank' : '_self'}
      >
        <DownloadIcon
          className={`h-10 w-10 rounded-lg bg-none stroke-[1.5] p-2 text-gray-500 transition-all  ${
            url && 'hover:bg-gray-100 hover:text-gray-700'
          }`}
          style={style}
        />
      </Link>
    </div>
  );
};
