import React from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';
import Link from 'next/link';

export type NavigationItem = {
  children?: NavigationItem[];
  current?: boolean;
  external?: boolean;
  href: string;
  id?: string;
  name: string;
  onLinkClick?: () => void;
};

interface NavItemProps {
  item: NavigationItem;
}

const MenuItemLink: React.FC<{
  children: React.ReactNode;
  className?: string;
  external?: boolean;
  href: string;
  onLinkClick?: () => void;
}> = ({ children, className, external, href, onLinkClick }) => {
  const linkProps = external
    ? { rel: 'noopener noreferrer', target: '_blank' }
    : {};

  return (
    <Link
      className={className}
      href={href}
      onClick={onLinkClick}
      {...linkProps}
    >
      {children}
      {external && <ArrowTopRightOnSquareIcon className="ml-1 size-4" />}
    </Link>
  );
};

export const NavItem: React.FC<NavItemProps> = ({
  item: { children, current, external, href = '', name, onLinkClick },
}) => {
  const currentIsChild = children?.some((child) => child.current);
  const baseClasses =
    'group relative inline-flex h-full items-center gap-1 font-body text-sm text-company-accent-text';
  const innerDivClasses =
    'min-h-8 pt-[6px] border-b-2 transition-colors duration-200';

  const linkContent = (
    <div
      className={clsx(
        innerDivClasses,
        current || currentIsChild
          ? 'border-b-company-accent-text'
          : 'border-b-transparent group-hover:border-b-company-accent-text'
      )}
    >
      {name}
    </div>
  );

  if (children && children.length) {
    return (
      <div className="group relative inline-block text-left">
        <div className={baseClasses}>
          {linkContent}
          <svg
            className="size-4 text-company-accent-text"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              clipRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              fillRule="evenodd"
            />
          </svg>
        </div>
        <div className="invisible absolute z-50 mt-1 w-56 origin-top-left rounded-md bg-white opacity-0 shadow-lg ring-1 ring-black/5 transition-all duration-200 focus:outline-none group-hover:visible group-hover:opacity-100">
          <div className="py-1">
            {children.map((child) => (
              <MenuItemLink
                key={`${child.href}-${child.name}`}
                className={clsx(
                  'flex items-center justify-between px-4 py-2 text-sm',
                  child.current
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                )}
                external={child.external}
                href={child.href}
                onLinkClick={child.onLinkClick}
              >
                {child.name}
              </MenuItemLink>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <MenuItemLink
      className={baseClasses}
      external={external}
      href={href}
      onLinkClick={onLinkClick}
    >
      {linkContent}
    </MenuItemLink>
  );
};
