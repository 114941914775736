'use client'; // https://www.blocknotejs.org/docs/advanced/nextjs - can't use blocknote in server side
import React, { useEffect, useState } from 'react';
import { Block } from '@blocknote/core';
import { useCreateBlockNote } from '@blocknote/react';

interface RenderBlockNoteProps {
  className?: string;
  content: Block[];
}

const RenderBlockNote: React.FC<RenderBlockNoteProps> = ({
  className = 'prose font-body text-lg leading-normal text-gray-700',
  content,
}) => {
  const [contentHTML, setcontentHTML] = useState<string>('');
  const editor = useCreateBlockNote();

  useEffect(() => {
    const convertDescriptionToHTML = async () => {
      if (content) {
        const html = await editor.blocksToHTMLLossy(content);
        setcontentHTML(html);
      }
    };
    convertDescriptionToHTML();
  }, [editor, content]);

  const contentIsEmpty =
    Array.isArray(content) &&
    content.length === 1 &&
    Array.isArray(content[0].content) &&
    content[0].content.length === 0 &&
    content[0].children?.length === 0;

  if (contentIsEmpty || !contentHTML) {
    return null;
  }

  return (
    <div
      dangerouslySetInnerHTML={{ __html: contentHTML }}
      className={className}
    />
  );
};

export default RenderBlockNote;
