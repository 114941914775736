import React from 'react';
import {
  FilterCheckBox,
  FilterDatePicker,
  FilterModal,
  FilterType,
  Typography,
  dateGetter,
} from '../../../index';

interface Props {
  appliedFilters: FilterType[];
  onClose: () => void;
  open: boolean;
  selectedFilters: FilterType[];
  setAppliedFilters: React.Dispatch<React.SetStateAction<FilterType[]>>;
  setSelectedFilters: React.Dispatch<React.SetStateAction<FilterType[]>>;
}

const MEDIA_TYPES = ['image', 'video', 'pdf', 'url', 'none'];

const MEDIA_TYPE_LABELS = {
  endDate: dateGetter,
  image: 'Image',
  none: 'No media',
  pdf: 'PDF',
  startDate: dateGetter,
  url: 'Link',
  video: 'Video',
};
const MEDIA_TYPE_KEY_LABELS = MEDIA_TYPES.map((key) => ({
  key,
  label: MEDIA_TYPE_LABELS[key as keyof typeof MEDIA_TYPE_LABELS],
}));

export const UpdatesBlockFilterModal: React.ComponentType<Props> = ({
  appliedFilters,
  onClose,
  open,
  selectedFilters,
  setAppliedFilters,
  setSelectedFilters,
}) => {
  return (
    <FilterModal
      allowOverflow
      appliedFilters={appliedFilters}
      open={open}
      selectedFilters={selectedFilters}
      setAppliedFilters={setAppliedFilters}
      setSelectedFilters={setSelectedFilters}
      title="Updates Filters"
      onClose={onClose}
    >
      <div className="space-y-6">
        <div className="grid grid-cols-8 gap-2 sm:grid-cols-12">
          <div className="col-span-8 grid w-full grid-cols-8 gap-2 sm:col-span-12 sm:grid-cols-12">
            <Typography className="col-span-7 sm:col-span-12" variant="button">
              Date range
            </Typography>
          </div>
          <div className="relative col-span-12 col-start-1 w-full space-y-4 sm:col-span-12">
            <FilterDatePicker
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          </div>
        </div>
        <div className="col-span-8 grid w-full grid-cols-8 gap-2 sm:col-span-12 sm:grid-cols-12">
          <Typography className="col-span-7 sm:col-span-12" variant="button">
            Media type
          </Typography>
        </div>
        <div className="relative col-span-12 col-start-1 w-full space-y-4 sm:col-span-11 sm:col-start-2">
          {MEDIA_TYPE_KEY_LABELS.filter(
            ({ key }) => !['startDate', 'endDate'].includes(key)
          ).map(({ key, label }) => {
            return (
              <FilterCheckBox
                key={key}
                keyValue={key}
                label={label as string}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
              />
            );
          })}
        </div>
      </div>
    </FilterModal>
  );
};
