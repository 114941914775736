import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

import { SwitchVerticalIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { LinkButton } from '../link-button';

// Can be extended if need be
export type SortOptionValue = string | number;

export interface SortOption {
  isActive: boolean;
  label: string;
  onSort: () => void;
  value: SortOptionValue;
}

export interface SortProps {
  options: SortOption[];
}

export const SortButton: React.FC<SortProps> = ({ options }) => {
  const activeOption = options.find((option) => option.isActive) || options[0];
  return (
    <Menu as="div" className="relative inline-block grow text-left md:grow-0">
      {({ open }) => {
        return (
          <>
            <Menu.Button
              as="div"
              className="flex grow items-center justify-center md:grow-0"
            >
              <LinkButton className="typography-button group flex min-w-max grow items-center justify-center gap-1 rounded-lg border border-secondary-grey-light px-4 py-2.5 text-text-secondary no-underline transition-all hover:border-gray-300 hover:text-text-main hover:no-underline hover:shadow-md md:grow-0">
                <SwitchVerticalIcon className="size-5" />
                <span className="inline-block min-w-[70px]">
                  {activeOption.label}
                </span>
              </LinkButton>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              show={open}
            >
              <Menu.Items
                static
                className={clsx(
                  'absolute inset-x-0 z-50 mt-2 min-w-max origin-top-left overflow-hidden rounded-md bg-white shadow-lg focus:outline-none'
                )}
              >
                {options.map((option) => {
                  return (
                    <Menu.Item key={option.value} as={Fragment}>
                      {({ active }) => (
                        <span
                          className={clsx(
                            option.value === activeOption.value &&
                              'bg-gray-100',
                            active ? 'bg-gray-100' : '',
                            'flex cursor-pointer flex-col p-4 font-system'
                          )}
                          role="button"
                          tabIndex={-1}
                          onClick={() => option.onSort()}
                        >
                          {option.label}
                        </span>
                      )}
                    </Menu.Item>
                  );
                })}
              </Menu.Items>
            </Transition>
          </>
        );
      }}
    </Menu>
  );
};

export default SortButton;
