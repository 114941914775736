import React, { useEffect, useMemo, useState } from 'react';
import { PhotographIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { getCldImageUrl } from 'next-cloudinary';
import { BLOCK_WIDTH_CLASS } from '../constants';
import { ImageMeta } from '../types';
import CloudinaryImage from '../ui/cloudinary-image';

export interface ImageBlockProps {
  content: {
    alt?: string;
    image?: ImageMeta | null;
  };
  style?: React.CSSProperties;
}

export const ImageBlock: React.ComponentType<ImageBlockProps> = ({
  content,
  style,
}) => {
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  useEffect(() => {
    if (!content.image) return;

    const { cloudinaryPublicId, cloudinaryUrl, height, width } = content.image;
    if (height && width) {
      setDimensions({ height, width });
    } else if (cloudinaryUrl) {
      const img = new Image();
      img.src = cloudinaryUrl as string;
      img.onload = () => {
        setDimensions({ height: img.height, width: img.width });
      };
    } else if (cloudinaryPublicId) {
      const img = new Image();
      img.src = getCldImageUrl({
        height: img.height,
        src: cloudinaryPublicId,
        width: img.width,
      });
    }
  }, [content.image]);

  const aspectRatioClass =
    dimensions.height && dimensions.width
      ? `aspect-[${dimensions.width}/${dimensions.height}]`
      : '';

  const objectFitClass =
    dimensions.height > 1080 ? 'object-contain' : 'object-cover';

  const maybeAddRoundedCornersToImageUrl = useMemo(() => {
    if (!content.image || !content.image.cloudinaryUrl) return '';
    if (content.image.cloudinaryUrl) {
      const url = content.image.cloudinaryUrl;

      if (objectFitClass !== 'object-contain') {
        return url;
      }

      const searchPattern = 'upload/';
      const index = url.indexOf(searchPattern);

      if (index === -1) {
        return url;
      }

      const prefix = url.slice(0, index + searchPattern.length);
      const suffix = url.slice(index + searchPattern.length);

      return `${prefix}r_16/${suffix}`;
    }
    if (content.image.cloudinaryPublicId) {
      return getCldImageUrl({
        radius: '16',
        src: content.image.cloudinaryPublicId,
      });
    }
    return '';
  }, [content, objectFitClass]);

  return (
    <div className={clsx(BLOCK_WIDTH_CLASS, 'relative')} style={style}>
      {!!dimensions.width && !!dimensions.height && !!content.image ? (
        <div
          className={clsx(
            'relative flex w-full justify-center rounded-lg',
            aspectRatioClass
          )}
        >
          <CloudinaryImage
            alt={content.alt || 'Untitled image'}
            className={clsx(`max-h-[1080px] rounded-lg`, objectFitClass)}
            height={dimensions.height}
            sizes="100vw"
            src={maybeAddRoundedCornersToImageUrl}
            width={dimensions.width}
          />
        </div>
      ) : (
        <div className="flex justify-center">
          <div
            className="flex items-center justify-center gap-2 rounded-lg bg-gray-100 p-4 text-gray-500"
            style={{ height: 200, width: 200 }}
          >
            <PhotographIcon className="h-6 w-6" />
            <span className="text-sm">No image</span>
          </div>
        </div>
      )}
    </div>
  );
};
