import punycode from 'punycode';
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  convertFromHTML,
  convertFromRaw,
  DraftStyleMap,
  EditorState,
} from 'draft-js';
import Link, { findLinkEntities } from './link';
import type { Options } from 'draft-js-export-html';

export const BLOCK_TYPES: {
  label: React.ReactNode;
  style: string;
}[] = [
  // {
  //   label: (
  //     <svg
  //       fill="currentColor"
  //       height="20px"
  //       viewBox="0 0 24 24"
  //       width="20px"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path d="M0 0h24v24H0z" fill="none" />
  //       <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14h-2V9h-2V7h4v10z" />
  //     </svg>
  //   ),
  //   style: 'header-one',
  // },
  // {
  //   label: (
  //     <svg
  //       fill="currentColor"
  //       height="20px"
  //       viewBox="0 0 24 24"
  //       width="20px"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path d="M0 0h24v24H0z" fill="none" />
  //       <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4 8c0 1.11-.9 2-2 2h-2v2h4v2H9v-4c0-1.11.9-2 2-2h2V9H9V7h4c1.1 0 2 .89 2 2v2z" />
  //     </svg>
  //   ),
  //   style: 'header-two',
  // },
  // {
  //   label: (
  //     <svg
  //       fill="currentColor"
  //       height="20px"
  //       viewBox="0 0 24 24"
  //       width="20px"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path d="M.01 0h24v24h-24z" fill="none" />
  //       <path d="M19.01 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4 7.5c0 .83-.67 1.5-1.5 1.5.83 0 1.5.67 1.5 1.5V15c0 1.11-.9 2-2 2h-4v-2h4v-2h-2v-2h2V9h-4V7h4c1.1 0 2 .89 2 2v1.5z" />
  //     </svg>
  //   ),
  //   style: 'header-three',
  // },
  // {
  //   label: (
  //     <svg
  //       fill="currentColor"
  //       height="20px"
  //       viewBox="0 0 24 24"
  //       width="20px"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path d="M0 0h24v24H0z" fill="none" />
  //       <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4 14h-2v-4H9V7h2v4h2V7h2v10z" />
  //     </svg>
  //   ),
  //   style: 'header-four',
  // },
  // {
  //   label: (
  //     <svg
  //       fill="currentColor"
  //       height="20px"
  //       viewBox="0 0 24 24"
  //       width="20px"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path d="M0 0h24v24H0z" fill="none" />
  //       <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4 6h-4v2h2c1.1 0 2 .89 2 2v2c0 1.11-.9 2-2 2H9v-2h4v-2H9V7h6v2z" />
  //     </svg>
  //   ),
  //   style: 'header-five',
  // },
  // {
  //   label: (
  //     <svg
  //       fill="currentColor"
  //       height="20px"
  //       viewBox="0 0 24 24"
  //       width="20px"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path d="M0 0h24v24H0z" fill="none" />
  //       <path d="M11 15h2v-2h-2v2zm8-12H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4 6h-4v2h2c1.1 0 2 .89 2 2v2c0 1.11-.9 2-2 2h-2c-1.1 0-2-.89-2-2V9c0-1.11.9-2 2-2h4v2z" />
  //     </svg>
  //   ),
  //   style: 'header-six',
  // },
  // {
  //   label: (
  //     <svg
  //       fill="currentColor"
  //       height="20px"
  //       viewBox="0 0 24 24"
  //       width="20px"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path d="M0 0h24v24H0z" fill="none" />
  //       <path d="M6 17h3l2-4V7H5v6h3zm8 0h3l2-4V7h-6v6h3z" />
  //     </svg>
  //   ),
  //   style: 'blockquote',
  // },
  // {
  //   label: (
  //     <svg
  //       fill="currentColor"
  //       height="20px"
  //       viewBox="0 0 24 24"
  //       width="20px"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path d="M0 0h24v24H0V0z" fill="none" />
  //       <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z" />
  //     </svg>
  //   ),
  //   style: 'unordered-list-item',
  // },
  // {
  //   label: (
  //     <svg
  //       fill="currentColor"
  //       height="20px"
  //       viewBox="0 0 24 24"
  //       width="20px"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path d="M0 0h24v24H0z" fill="none" />
  //       <path d="M2 17h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1 3h1.8L2 13.1v.9h3v-1H3.2L5 10.9V10H2v1zm5-6v2h14V5H7zm0 14h14v-2H7v2zm0-6h14v-2H7v2z" />
  //     </svg>
  //   ),
  //   style: 'ordered-list-item',
  // },
  // {
  //   label: (
  //     <svg
  //       enableBackground="new 0 0 24 24"
  //       fill="currentColor"
  //       height="20px"
  //       viewBox="0 0 24 24"
  //       width="20px"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <g>
  //         <rect fill="none" height="24" width="24" />
  //       </g>
  //       <g>
  //         <path d="M20,4H4C2.89,4,2,4.9,2,6v12c0,1.1,0.89,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.11,4,20,4z M20,18H4V8h16V18z M18,17h-6v-2 h6V17z M7.5,17l-1.41-1.41L8.67,13l-2.59-2.59L7.5,9l4,4L7.5,17z" />
  //       </g>
  //     </svg>
  //   ),
  //   style: 'code-block',
  // },
];

export const CUSTOM_STYLE_MAP: DraftStyleMap = {
  BOLD: {
    fontWeight: 700,
  },
  CODE: {
    backgroundColor: '#E3E3E3',
    borderRadius: 4,
    fontFamily:
      'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    paddingBottom: 4,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 4,
  },
};

export const INLINE_STYLES = [
  {
    label: (
      <svg
        fill="currentColor"
        height="20px"
        viewBox="0 0 24 24"
        width="20px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zM10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z" />
      </svg>
    ),
    style: 'BOLD',
  },
  {
    label: (
      <svg
        fill="currentColor"
        height="20px"
        viewBox="0 0 24 24"
        width="20px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z" />
      </svg>
    ),
    style: 'ITALIC',
  },
  {
    label: (
      <svg
        fill="currentColor"
        height="20px"
        viewBox="0 0 24 24"
        width="20px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 17c3.31 0 6-2.69 6-6V3h-2.5v8c0 1.93-1.57 3.5-3.5 3.5S8.5 12.93 8.5 11V3H6v8c0 3.31 2.69 6 6 6zm-7 2v2h14v-2H5z" />
      </svg>
    ),
    style: 'UNDERLINE',
  },
  // {
  //   label: (
  //     <svg
  //       fill="currentColor"
  //       height="20px"
  //       viewBox="0 0 24 24"
  //       width="20px"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path d="M0 0h24v24H0V0z" fill="none" />
  //       <path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z" />
  //     </svg>
  //   ),
  //   style: 'CODE',
  // },
];

export const STATE_TO_HTML_OPTIONS: Options = {
  blockStyleFn: (block) => {
    switch (block.getType()) {
      case 'header-one':
        return {
          attributes: {
            className: 'typography-heading-1',
          },
        };

      case 'header-two':
        return {
          attributes: {
            className: 'typography-heading-2',
          },
        };

      case 'header-three':
        return {
          attributes: {
            className: 'typography-heading-3',
          },
        };

      case 'header-four':
        return {
          attributes: {
            className: 'typography-heading-4',
          },
        };

      case 'header-five':
        return {
          attributes: {
            className: 'typography-heading-5',
          },
        };

      case 'header-six':
        return {
          attributes: {
            className: 'typography-subtitle-1',
          },
        };

      case 'blockquote':
        return {
          attributes: {
            className:
              'pl-3 my-4 font-semibold text-text-grey border-l-2 border-secondary-grey-light',
          },
        };

      case 'code-block':
        return {
          attributes: {
            className: 'py-2 px-3 my-4 text-xs bg-secondary-grey-light rounded',
          },
        };

      case 'unordered-list-item':
        return {
          attributes: {
            className: 'ml-6 list-disc',
          },
        };

      case 'ordered-list-item':
        return {
          attributes: {
            className: 'ml-6 list-decimal',
          },
        };

      default:
        return {
          attributes: {
            className: 'typography-body-regular',
          },
        };
    }
  },
  entityStyleFn: (entity) => {
    switch (entity.getType()) {
      case 'LINK':
        return {
          attributes: {
            className: 'text-primary-green-dark underline',
            href: entity.getData().url,
          },
          element: 'a',
        };

      default:
        return {};
    }
  },
};

export function createState(html?: string) {
  const decorator = new CompositeDecorator([
    {
      component: Link,
      strategy: findLinkEntities,
    },
  ]);

  if (html) {
    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    return EditorState.createWithContent(state, decorator);
  }

  const emptyContentState = convertFromRaw({
    blocks: [
      {
        depth: 0,
        entityRanges: [],
        inlineStyleRanges: [],
        key: 'initial',
        text: '',
        type: 'unstyled',
      },
    ],
    entityMap: {},
  });

  return EditorState.createWithContent(emptyContentState, decorator);
}

export function getBlockStyle(block: ContentBlock): string {
  switch (block.getType()) {
    case 'header-one':
      return 'typography-heading-1';

    case 'header-two':
      return 'typography-heading-2';

    case 'header-three':
      return 'typography-heading-3';

    case 'header-four':
      return 'typography-heading-4';

    case 'header-five':
      return 'typography-heading-5';

    case 'header-six':
      return 'typography-subtitle-1';

    case 'blockquote':
      return 'pl-3 my-4 font-semibold text-text-grey border-l-2 border-secondary-grey-light';

    case 'code-block':
      return 'py-2 px-3 my-4 text-xs bg-secondary-grey-light rounded';

    default:
      return 'typography-body-regular';
  }
}

export function getCharCount(editorState: EditorState): number {
  const decodeUnicode = (str: string): number[] => punycode.ucs2.decode(str); // func to handle unicode characters
  const plainText = editorState.getCurrentContent().getPlainText('');
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = plainText.replace(regex, '').trim(); // replace above characters w/ nothing

  return decodeUnicode(cleanString).length;
}

export function getWordCount(editorState: EditorState): number {
  const plainText = editorState.getCurrentContent().getPlainText('');
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = plainText.replace(regex, ' ').trim(); // replace above characters w/ space
  const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
  return wordArray ? wordArray.length : 0;
}
