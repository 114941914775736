import React, { useCallback, useMemo, useRef } from 'react';
import { Menu } from '@headlessui/react';
import clsx from 'clsx';
import { RichUtils } from 'draft-js';
import { ActionDownIcon } from '../icons';
import Typography from '../typography';
import { useEditor } from './context';

const TextStyleMenu: React.ComponentType = () => {
  const { editorState, setEditorState } = useEditor();

  const storedEditorState = useRef(editorState);

  const toggleBlockType = useCallback(
    (blockType: string) => {
      setEditorState(
        RichUtils.toggleBlockType(storedEditorState.current, blockType)
      );
    },
    [setEditorState]
  );

  const currentBlockType = useMemo(() => {
    const selection = editorState.getSelection();

    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    switch (blockType) {
      case 'header-three':
        return 'Heading';
      case 'header-six':
        return 'Subtitle';
      default:
        return 'Body';
    }
  }, [editorState]);

  const onMouseDown = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault();
      storedEditorState.current = editorState;
    },
    [editorState]
  );

  return (
    <Menu as="div" className="relative">
      <Menu.Button
        className="flex items-center gap-1"
        onMouseDown={onMouseDown}
      >
        <Typography className="text-text-secondary" variant="body-small">
          {currentBlockType}
        </Typography>
        <ActionDownIcon
          className="fill-text-secondary"
          height={18}
          width={18}
        />
      </Menu.Button>
      <Menu.Items className="absolute z-10 mt-2 w-24 bg-white shadow">
        <Menu.Item>
          {({ active }) => (
            <div
              className={clsx(
                'typography-body-small cursor-pointer px-2 py-1 text-text-secondary',
                active && 'bg-secondary-grey-light-2'
              )}
              onClick={() => toggleBlockType('paragraph')}
            >
              Body
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              className={clsx(
                'typography-subtitle-2 cursor-pointer px-2 py-1 text-text-secondary',
                active && 'bg-secondary-grey-light-2'
              )}
              onClick={() => toggleBlockType('header-six')}
            >
              Subtitle
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              className={clsx(
                'typography-subtitle-1 cursor-pointer px-2 py-1 text-text-secondary',
                active && 'bg-secondary-grey-light-2'
              )}
              onClick={() => toggleBlockType('header-three')}
            >
              Heading
            </div>
          )}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

export default TextStyleMenu;
