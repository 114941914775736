import React, {
  MutableRefObject,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  createChart,
  IChartApi,
  SeriesMarker,
  TickMarkType,
  Time,
  UTCTimestamp,
} from 'lightweight-charts';
import numeral from 'numeral';
import { match, P } from 'ts-pattern';
import {
  DateSelect,
  Checkbox,
  CircularProgress,
  Skeleton,
  Typography,
  crosshairMoveHandler,
  MarketOverview,
  ChartLegend,
  MarketOverviewTable,
  Maybe,
  MediaAnnouncement,
  useCurrentCompanyOverviewQuery,
  useCurrentCompanyMarketDataSummaryQuery,
} from '../../../index';

dayjs.extend(utc);
dayjs.extend(timezone);

const ChartLoading = (): JSX.Element => (
  <div className="flex h-96 w-full flex-row">
    <div className="m-auto">
      <CircularProgress />
    </div>
  </div>
);

function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

interface MarketDataBlockProps {
  client?: ApolloClient<NormalizedCacheObject>;
  content: {
    chartLineColour?: string;
    heading?: string;
  };
  isUK: boolean;
  listingKey: string;
  marketKey: string;
  themeColourAccent: string;
  themeColourPrimary: string;
  translate: (key: string) => string;
}

export const MarketDataBlock = ({
  client,
  content,
  isUK,
  listingKey,
  marketKey,
  themeColourAccent,
  themeColourPrimary,
  translate,
}: MarketDataBlockProps): JSX.Element => {
  const { chartLineColour, heading = 'Market data' } = content;

  const [endDate, setEndDate] = useState<Maybe<Date>>(dayjs().toDate());
  const [startDate, setStartDate] = useState<Maybe<Date>>(
    dayjs().subtract(1, 'year').toDate()
  );
  const {
    data: companyOverviewData,
    error: companyOverviewError,
    loading: companyOverviewLoading,
    networkStatus,
  } = useCurrentCompanyOverviewQuery({
    client,
    skip: !startDate || !endDate,
    variables: {
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
    },
  });

  const { data: marketSummaryData, loading: marketSummaryLoading } =
    useCurrentCompanyMarketDataSummaryQuery({
      client,
      pollInterval: 30000,
    });
  const chartRef = useRef<HTMLDivElement>(null);
  const chartApiRef: MutableRefObject<IChartApi | undefined> = useRef();
  const tooltipRef = useRef<HTMLSpanElement>(null);

  const [showAnnouncements, setShowAnnouncements] = useState(false);
  const currentCompanyMarketDataSummary =
    marketSummaryData?.currentCompanyMarketDataSummary;

  const getChartLineColor = useCallback(() => {
    return match(chartLineColour)
      .with('blue', () => '#2962FF')
      .with('accent', () => themeColourAccent)
      .otherwise(() => themeColourPrimary);
  }, [chartLineColour, themeColourPrimary, themeColourAccent]);

  function getChartLineClass() {
    return match(chartLineColour)
      .with('blue', () => 'bg-chart-blue-med')
      .with('accent', () => 'bg-company-accent')
      .otherwise(() => 'bg-company-primary');
  }

  useEffect(() => {
    // map announcements to dates for the tooltip
    const dateMappedAnnouncements: Record<string, MediaAnnouncement[]> =
      companyOverviewData && companyOverviewData.currentCompanyOverview
        ? companyOverviewData.currentCompanyOverview.reduce(
            (acc: Record<string, MediaAnnouncement[]>, companyOverview) => {
              if (companyOverview && showAnnouncements) {
                const date = companyOverview?.date;
                const announcements = companyOverview.announcements.filter(
                  notEmpty
                ) as MediaAnnouncement[];

                acc[date] = announcements;

                return acc;
              } else {
                return acc;
              }
            },
            {}
          )
        : {};

    const handleResize = (chartApiRef: IChartApi | undefined) => {
      chartApiRef?.applyOptions({ width: chartRef?.current?.clientWidth });
      chartApiRef?.timeScale().fitContent();
    };

    if (chartRef.current && !(marketSummaryLoading || companyOverviewLoading)) {
      chartApiRef.current = createChart(chartRef.current, {
        handleScale: false,
        handleScroll: false,
      });

      chartApiRef.current.applyOptions({
        crosshair: {
          horzLine: {
            labelVisible: false,
            visible: false,
          },
        },
        layout: {
          textColor: '#787878',
        },
        leftPriceScale: {
          borderColor: '#E5E7EB',
          scaleMargins: { bottom: 0, top: 0.1 },
          visible: true,
        },
        rightPriceScale: {
          borderColor: '#E5E7EB',
          visible: true,
        },
        timeScale: {
          borderColor: '#E5E7EB',
          tickMarkFormatter: (
            time: Time,
            _tickMarkType: TickMarkType,
            _locale: string
          ) =>
            match({ time })
              .with({ time: P.number }, ({ time }) =>
                dayjs.unix(time).format('DD MMM')
              )
              .with(
                {
                  time: {
                    day: P.number,
                    month: P.number,
                    year: P.number,
                  },
                },
                ({ time }) => {
                  const date = new Date(time.year, time.month, time.day);
                  return dayjs(date).format('DD MMM');
                }
              )
              .with({ time: P.string }, ({ time }) => {
                const date = new Date(time);
                return dayjs(date).format('DD MMM');
              })
              .run(),
        },
      });

      chartApiRef.current?.timeScale().fitContent();
      window.addEventListener('resize', () =>
        handleResize(chartApiRef.current)
      );

      const chartContainer = document.getElementById('chart-container');

      if (chartContainer) {
        chartContainer.addEventListener('mouseleave', () => {
          const tooltip = document.getElementById('market-data-crosshair-tt');
          const tooltipIsInvisible = () =>
            tooltip?.classList.contains('market-data-tt-box--invisible');

          if (tooltip) {
            tooltip.addEventListener('transitionend', () => {
              if (tooltipIsInvisible()) {
                tooltip.style.display = 'none';
              }
            });

            if (!tooltipIsInvisible()) {
              tooltip.classList.add('market-data-tt-box--invisible');
            }
          }
        });
      }

      chartApiRef.current?.subscribeCrosshairMove((crosshairParams) => {
        crosshairMoveHandler(
          crosshairParams,
          chartRef,
          tooltipRef,
          dateMappedAnnouncements,
          translate
        );
      });

      if (chartApiRef && companyOverviewData?.currentCompanyOverview) {
        const companyDataPoints =
          companyOverviewData.currentCompanyOverview.filter(notEmpty);

        const volumeSeries = chartApiRef.current.addHistogramSeries({
          color: '#E3E3E3',
          lastValueVisible: false,
          priceFormat: {
            type: 'volume',
          },
          priceScaleId: 'left',
        });

        const volumeSeriesData = companyDataPoints
          .map((dataPoint) => {
            return {
              time: (new Date(dataPoint?.date ?? '').getTime() /
                1000) as UTCTimestamp,
              value: dataPoint?.volume ?? 0,
            };
          })
          .sort((a, b) => a.time - b.time)
          .filter(notEmpty);

        const latestPriceObj = currentCompanyMarketDataSummary?.updatedAt
          ? {
              time: dayjs(currentCompanyMarketDataSummary.updatedAt)
                .tz(isUK ? 'Europe/London' : 'Australia/Sydney')
                .format('YYYY-MM-DD'),
              value: currentCompanyMarketDataSummary?.lastTradedPrice as number,
            }
          : null;

        const latestVolumeObj = latestPriceObj
          ? {
              time: (new Date(latestPriceObj.time).getTime() /
                1000) as UTCTimestamp,
              value: currentCompanyMarketDataSummary?.volume ?? 0,
            }
          : null;

        const lineSeries = chartApiRef.current.addLineSeries({
          color: getChartLineColor(),
          lineWidth: 2,
          priceFormat: {
            formatter: (price: number) => {
              return numeral(price).format('0,0[.]00[0]');
            },
            type: 'custom',
          },
        });
        const lineSeriesData = companyDataPoints
          .map((dataPoint) => {
            if (dataPoint?.date && dataPoint.close) {
              return {
                time: dataPoint.date,
                value: dataPoint.close,
              };
            }
            return null;
          })
          .filter(notEmpty);

        if (
          latestPriceObj &&
          latestVolumeObj &&
          !lineSeriesData.find(
            (dataPoint) => dataPoint.time === latestPriceObj.time
          )
        ) {
          volumeSeries.setData(
            latestVolumeObj
              ? volumeSeriesData.concat(latestVolumeObj)
              : volumeSeriesData
          );
          lineSeries.setData(
            latestPriceObj
              ? lineSeriesData.concat(latestPriceObj)
              : lineSeriesData
          );
        } else {
          lineSeries.setData(lineSeriesData);
          volumeSeries.setData(volumeSeriesData);
        }

        const announcementMarkers = companyDataPoints.flatMap((dataPoint) => {
          return dataPoint?.announcements
            ? dataPoint.announcements.map((announcement) => {
                /*
                 * The chart jumps when we toggle "Announcements" on / off
                 * The field "size: showAnnouncements ? 0.01 : 0" prevents the jumps
                 *
                 * Reasoning behind: the announcement data point is always known to the chart,
                 * but we make the size visible when we want to show "Announcements"
                 */
                return {
                  color: '#3A3A3A',
                  position: 'inBar',
                  shape: 'circle',
                  /* 0.01 so that the marker size is consistent across all date ranges since the minimum size no matter how small you set it is 1
                   */
                  size: showAnnouncements ? 0.01 : 0,
                  time: (new Date(dataPoint.date).getTime() /
                    1000) as UTCTimestamp,
                };
              })
            : [];
        }) as SeriesMarker<UTCTimestamp>[];

        lineSeries.setMarkers(announcementMarkers);
      }
    }

    const refCopy = chartRef;
    return () => {
      window.removeEventListener('resize', () =>
        handleResize(chartApiRef.current)
      );

      // Only remove the chart if it's not already disposed
      // Otherwise there will be an error "Object is disposed"
      const query = 'div.tv-lightweight-charts';
      const chartNotDisposed = refCopy.current?.querySelector(query) != null;
      if (chartNotDisposed) chartApiRef.current?.remove();
    };
  }, [
    chartApiRef,
    chartRef,
    companyOverviewData,
    companyOverviewError,
    companyOverviewLoading,
    currentCompanyMarketDataSummary,
    isUK,
    marketSummaryLoading,
    networkStatus,
    showAnnouncements,
    translate,
    getChartLineColor,
  ]);

  const renderMarketStatus = () =>
    match({ isMarketOpen: currentCompanyMarketDataSummary?.isMarketOpen })
      .with({ isMarketOpen: true }, () => (
        <div className="flex w-full justify-between gap-2 md:items-center">
          <div className="flex flex-row items-center gap-1">
            <span className="relative flex h-[14px] w-[14px] items-center justify-center">
              <span
                className="absolute inline-flex h-full w-full animate-ping rounded-full bg-status-green/[0.5]"
                style={{ animationDuration: '1.5s' }}
              />
              <span className="relative inline-flex h-[8px] w-[8px] rounded-full bg-status-green" />
            </span>
            <Typography className="text-text-secondary" variant="system-small">
              Market open ({`${isUK ? '15' : '20'}`} minute delay)
            </Typography>
          </div>
          <Typography className=" text-text-secondary" variant="system-small">
            All prices in {currentCompanyMarketDataSummary?.currency ?? ''}
          </Typography>
        </div>
      ))
      .with({ isMarketOpen: false }, () => (
        <div className="flex w-full justify-between gap-2 md:items-center">
          <div className="flex flex-row items-center gap-1">
            <span className="flex h-[14px] w-[14px] items-center justify-center rounded-full bg-secondary-grey-dark/[0.5]">
              <span className="h-[8px] w-[8px] rounded-full bg-secondary-grey-dark" />
            </span>
            <Typography
              className="text-nowrap  text-text-secondary"
              variant="system-small"
            >
              Market closed
            </Typography>
          </div>
          <Typography className="text-text-secondary" variant="system-small">
            All prices in {currentCompanyMarketDataSummary?.currency ?? ''}
          </Typography>
        </div>
      ))
      .otherwise(() => (
        <Skeleton loading height={17.5} variant="rect" width="100px" />
      ));

  return (
    <div
      className="mx-auto flex w-full max-w-screen-xl scroll-mt-4 flex-col gap-6 px-4 sm:px-6 lg:scroll-mt-[120px]"
      id="market-data"
    >
      <div className="flex flex-col gap-4">
        {heading && (
          <Typography className="font-heading" variant="medium">
            {heading}
          </Typography>
        )}
        <div className="flex items-center gap-1">{renderMarketStatus()}</div>
      </div>

      <MarketOverview
        currency={currentCompanyMarketDataSummary?.currency}
        currentPrice={currentCompanyMarketDataSummary?.lastTradedPrice}
        high={currentCompanyMarketDataSummary?.yearHigh}
        loading={marketSummaryLoading || companyOverviewLoading}
        low={currentCompanyMarketDataSummary?.yearLow}
        marketCap={currentCompanyMarketDataSummary?.marketCap}
        percentageChange={currentCompanyMarketDataSummary?.percentageChange}
        volume={currentCompanyMarketDataSummary?.volume}
      />

      <div className="rounded-lg border border-y-gray-200 bg-white">
        <div className="flex w-full flex-col justify-between gap-4 border-b p-4 sm:flex-row sm:gap-0 md:p-6">
          <Typography
            className="text-text-main md:my-auto"
            variant="system-large"
          >
            Market Data Chart ({marketKey.toUpperCase()}:
            {listingKey.toUpperCase()})
          </Typography>
          <div className="flex flex-row items-center justify-between gap-6 sm:justify-start">
            <div className="group flex cursor-pointer items-center gap-2 p-0.5">
              <Checkbox
                checked={showAnnouncements}
                className="cursor-pointer rounded border-gray-300 transition-colors group-hover:border-gray-500"
                id="annDisplayToggle"
                onChange={() => ({})}
                onClick={() => setShowAnnouncements(!showAnnouncements)}
              />
              <label htmlFor="annDisplayToggle">
                <Typography
                  className="cursor-pointer font-semibold text-text-main"
                  component="span"
                  variant="system-regular"
                >
                  {translate('announcements.uppercase')}
                </Typography>
              </label>
            </div>
            <div className="my-auto w-auto">
              <DateSelect
                endDate={endDate}
                label={'no-label'}
                pastXDays={365}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                startDate={startDate}
              />
            </div>
          </div>
        </div>
        <div
          className="relative flex w-full flex-row space-y-4 px-0 py-8 md:px-16"
          id="chart-container"
        >
          <Typography
            className="absolute left-[-1.5%] top-[42%] -rotate-90 select-none font-semibold"
            variant="system-regular"
          >
            Volume traded
          </Typography>
          {companyOverviewLoading || marketSummaryLoading ? (
            <ChartLoading />
          ) : (
            <div ref={chartRef} className="h-96 w-full">
              <span ref={tooltipRef} />
            </div>
          )}
          <Typography
            className="absolute right-[-1.5%] top-[40%] -rotate-90 select-none font-semibold"
            variant="system-regular"
          >
            Share price ({currentCompanyMarketDataSummary?.currency})
          </Typography>
        </div>
        <ChartLegend
          sharePriceColourClass={getChartLineClass()}
          translate={translate}
        />
      </div>
      <MarketOverviewTable
        currentCompanyMarketDataSummary={currentCompanyMarketDataSummary}
        loading={marketSummaryLoading}
      />
    </div>
  );
};
