import React, { useState, useEffect, useRef, useCallback } from 'react';
import ShowMoreText from 'react-show-more-text';

interface PlainTextProps {
  allowShowMore?: boolean;
  className?: string;
  content?: string;
  maxLines?: number;
}

const PlainText: React.ComponentType<PlainTextProps> = ({
  allowShowMore = false,
  className = '',
  content,
  maxLines = 3,
}) => {
  const [isClamped, setIsClamped] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);

  const descriptionHTML = content ? content.replace(/\n/g, '<br />') : '';

  const checkOverflow = useCallback(() => {
    const element = descriptionRef.current;
    if (element) {
      // the height of a single line of text
      const lineHeight = parseFloat(
        window.getComputedStyle(element).lineHeight
      );

      // the max height of the content allowed without clamping, calculated by multiplying the line height by the max number of lines
      const maxHeight = lineHeight * maxLines;

      // by comparing the scroll height of the element to the max height, we can determine if the content is clamped
      setIsClamped(element.scrollHeight > maxHeight);
    }
  }, [maxLines]);

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [checkOverflow, content, maxLines]);

  return (
    <div className={className}>
      {allowShowMore && isClamped ? (
        <ShowMoreText
          anchorClass="text-company-primary"
          expanded={false}
          less="Show less"
          lines={maxLines}
          more="Show more"
        >
          <div
            dangerouslySetInnerHTML={{ __html: descriptionHTML }}
            ref={descriptionRef}
          />
        </ShowMoreText>
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: descriptionHTML }}
          ref={descriptionRef}
        />
      )}
    </div>
  );
};

export default PlainText;
