import React from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { format } from 'date-fns';
import Link from 'next/link';
import {
  BlockHeading,
  OptionsInput,
  useMediaAnnouncementsQuery,
  SuperRichTextEditor,
} from '../../index';
import { BLOCK_WIDTH_CLASS } from '../constants';

export interface LatestAnnouncementsFeaturedBlockProps {
  client?: ApolloClient<NormalizedCacheObject>;
  content: {
    heading?: string;
  };
  hub: string;
  listingKey: string;
  marketKey: string;
  translate: (key: string) => string;
}

export const LatestAnnouncementsFeaturedBlock: React.ComponentType<
  LatestAnnouncementsFeaturedBlockProps
> = ({ client, content, hub, listingKey, marketKey }) => {
  let options: OptionsInput = {
    filters: [
      { key: 'market_key', value: marketKey },
      { key: 'ticker', value: listingKey },
    ],
    orders: [{ key: 'posted_at', value: 'desc' }],
  };

  // CCRTODO: Kalgoorlie Gold Mining wants to show market sensitive announcements
  if (marketKey.toLowerCase() === 'kal') {
    options = {
      ...options,
      filters: [
        {
          key: 'is_market_sensitive',
          value: 'true',
        },
      ],
    };
  }

  const { data } = useMediaAnnouncementsQuery({
    client,
    fetchPolicy: 'network-only',
    variables: {
      first: 3,
      hub,
      options,
    },
  });

  const announcements = data?.mediaAnnouncements?.edges?.map(
    (edge) => edge?.node
  );
  if (!announcements || announcements.length === 0) {
    return null;
  }

  const heading = content.heading || '';

  const featuredAnnouncement = announcements[0];
  const otherAnnouncements = announcements.slice(1);

  if (!featuredAnnouncement) {
    return null;
  }

  return (
    <div className="bg-white" id="latest_announcements_block">
      <div className={`${BLOCK_WIDTH_CLASS} mx-auto max-w-7xl`}>
        {heading && (
          <BlockHeading withUnderline className="pb-8">
            {heading}
          </BlockHeading>
        )}
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-y-16">
          <article className="mx-auto w-full lg:mx-0">
            <time
              className="mb-2 block font-body text-sm leading-6 text-gray-600"
              dateTime={new Date(featuredAnnouncement.postedAt).toISOString()}
            >
              {format(
                new Date(featuredAnnouncement.postedAt),
                'MMM d, yyyy – h:mm a'
              )}
            </time>
            <Link
              className="mt-4 self-stretch font-heading text-2xl text-text-main transition-colors hover:text-gray-700 sm:text-3xl"
              href={`/announcements/${featuredAnnouncement.id}`}
            >
              {featuredAnnouncement.header}
            </Link>
            <div className="mt-4 line-clamp-5 text-gray-600 md:line-clamp-[16] lg:mt-5">
              <SuperRichTextEditor
                readOnly
                content={featuredAnnouncement.summary || ''}
              />
            </div>
            <div className="mt-4 flex flex-col justify-between gap-6 sm:mt-8 sm:gap-8 lg:mt-6 lg:flex-col">
              <Link
                className="group flex items-center gap-2 font-body text-base font-semibold leading-6 text-company-primary"
                href={`/announcements/${featuredAnnouncement.id}`}
              >
                View announcement{' '}
                <ArrowRightIcon className="size-3.5 transition-transform group-hover:translate-x-1/4" />
              </Link>
            </div>
          </article>
          <div className="mx-auto w-full border-t border-gray-900/10 pt-8 lg:mx-0 lg:border-t-0 lg:pt-0">
            <div className="divide-y divide-gray-900/10">
              {otherAnnouncements.map((announcement) => {
                if (!announcement) return null;
                return (
                  <article key={announcement.id} className="py-6 first:pt-0">
                    <div className="relative w-full lg:max-w-xl">
                      <time
                        className="mb-2 block font-body text-sm leading-6 text-gray-600"
                        dateTime={new Date(announcement.postedAt).toISOString()}
                      >
                        {format(
                          new Date(announcement.postedAt),
                          'MMM d, yyyy – h:mm a'
                        )}
                      </time>
                      <Link
                        className="mt-2 self-stretch font-body font-semibold text-gray-900 transition-colors hover:text-gray-800"
                        href={`/announcements/${announcement.id}`}
                      >
                        {announcement.header}
                      </Link>
                      <div className="mt-3 line-clamp-5 text-gray-600">
                        <SuperRichTextEditor
                          readOnly
                          content={announcement.summary || ''}
                        />
                      </div>
                      <div className="group mt-4 flex flex-col justify-between gap-6 sm:mt-8 sm:gap-8 lg:mt-6 lg:flex-col">
                        <Link
                          className="flex items-center gap-2 font-body text-base font-semibold leading-6 text-company-primary transition-opacity"
                          href={`/announcements/${announcement.id}`}
                        >
                          View announcement{' '}
                          <ArrowRightIcon className="size-3.5 transition-transform group-hover:translate-x-1/4" />
                        </Link>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
